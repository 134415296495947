import * as firebase from "firebase/app";
import {
  getAuth,
  signInAnonymously,
  connectAuthEmulator,
  onAuthStateChanged,
  getIdTokenResult,
} from "firebase/auth";

// export default async(cb: (uuid: string|null,) => void) =>
export default async function Login(
  cb: (uuid: string | null, token: string | null) => void
) {
  try {
    const firebaseConfig = {
      apiKey: "AIzaSyBX_ZRVgfgTNSbptJRd_AHl9o44DB7V2xA",
      authDomain: "csrautoecole.firebaseapp.com",
      projectId: "csrautoecole",
      storageBucket: "csrautoecole.appspot.com",
      messagingSenderId: "291470752534",
      appId: "1:291470752534:web:afe984848359f098e06e37",
    };
    // Initialize Firebase
    const app = firebase.initializeApp(firebaseConfig);
    const auth = getAuth(app);
    //REMOVE PROD
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      (auth as unknown as any)._canInitEmulator = true;
      connectAuthEmulator(auth, "http://localhost:9099", {
        disableWarnings: true,
      });
    }
    //REMOVE PROD
    await signInAnonymously(auth);

    onAuthStateChanged(auth, (user) => {
      getIdTokenResult(user as any, false)
        .then((data) => {
          if (user) cb(user.uid, data.token);
        })
        .catch((e) => {
          if (user) cb(user.uid, null);
        });
    });
  } catch (err) {
    console.log("error to get data" + err);
    cb(null, null);
  }
}
